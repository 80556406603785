<template>
    <div>
      <backToHomeButton :title="backToHomeButtonText" v-if="!panels.infoWrapper && showHome"/>
      <div class="top-menu" :class="{'light': slides[currentSlide] && slides[currentSlide].type !== 3}">
        <ul>
          <template v-for="(item, i) in slides">
            <li :key="'key-menu-' + i" v-if="item.menuTitle && (item.showOnTopMenu || item.parent)">
              <a href="#" v-if="item.showOnTopMenu" @click.stop.prevent="handleMenu(item, i)"
                 :class="{ 'active' : slides[currentSlide] && slides[currentSlide].menuTitle == item.menuTitle }">{{ item.menuTitle }}</a>
              <div class="top-menu-sub-list"
                   v-if="dropdownMenu.show && dropdownMenu.list.length > 0 && dropdownMenu.index == i">
                <div class="d-flex justify-content-end">
                  <span class="menu-close-icon" @click="closeMenu()">
                    <img :src="require('@/assets/images/cancel-white.svg')" alt="">
                  </span>
                </div>
                <ul>
                  <li v-for="(ditem, index) in dropdownMenu.list" :key="'sub-menu-' + index">
                    <a @click="handleSubMenu(ditem.realIndex)" v-text="ditem.menu_title"
                       :class="{'active': slides[currentSlide] && parseInt(slides[currentSlide].id) === parseInt(ditem.id)}"></a>
                  </li>
                </ul>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <!-- <div class="top-menu">
        <ul>
        <template v-for="(item, i) in slides">
          <li :key="'key-menu-' + i" v-if="item.menuTitle && item.showOnTopMenu">
            <a @click="changeSlides(i)"
            v-text="item.menuTitle"
            :class="{
              'active': slides[currentSlide] && slides[currentSlide].menuTitle == item.menuTitle
            }" ></a>
          </li>
        </template>
        </ul>
      </div> -->
      <div class="app-inner" v-if="!slideWithPassword">
        <div class="slides-wrapper slides-wrapper-intro9 intro9 intro10 intro18 clip" @click="changeSlides">
          <template v-for="(item, index) in slides">
            <div :key="'a-slide' + index" class="a-slide" :class="{
              'animate' : (animate && currentSlide == index),
              'active': currentSlide == index,
              ['a-slide-type-' + item.type] : !item.hasBg,
              'show-arrows-of-slider': isSliderActive,
              'preSlide': index < currentSlide
            }" :style="{ backgroundImage : 'url(' + baseUrl + item.img + ')' }">

              <div
                v-if="item.type == 5"
                class="a-slide-up-cont"
                :class="{
                  'main': item.contentPosition === 'center'
                }"
              >
                <h1 v-if="item.title" v-html="item.title"></h1>
                <h2 v-if="item.subTitle" v-html="item.subTitle"></h2>
              </div>
              <div
                v-if="item.type == 5"
                class="singleImageContent a-slide-down-cont"
                :class="[{
                'halfSpace': item.contentPosition !== 'center' && item.subTitle,
                'hasSlides': item.hasSlides
                }]"
              >
                <ul class="listImage" v-if="item.sponsImg && item.sponsImg.length > 0">
                  <template v-for="(itm, i) in item.sponsImg">
                    <li :key="'spimg' + i">
                      <img v-if="itm.img" :src="(baseUrl + itm.img)" alt="">
                      <span v-if="itm.title" v-text="itm.title"></span>
                    </li>
                  </template>
                </ul>
              </div>
              <button class="btn" v-if="item.hasShowMoreButton" @click.prevent.stop="digitalCheckout = true">
                Mehr erfahren
              </button>
              <TariffCompareSimple
              v-if="item.type === 8 && item.simple !== undefined"
              :item="item"
              :baseUrl="baseUrl"
              @changeSlides="changeSlides" />
              <TariffCompare
              v-if="item.type === 8 && item.custom_discount === undefined && item.simple === undefined && item.cards_tariff === undefined"
              :item="item"
              :baseUrl="baseUrl"
              @changeSlides="changeSlides" />
              <TariffCompareCustom
              v-if="item.type === 8 && item.custom_discount !== undefined"
              :item="item"
              :baseUrl="baseUrl"
              @changeSlides="changeSlides" />
              <TariffCompareCards
              v-if="item.type === 8 && item.cards_tariff !== undefined"
              :item="item"
              :baseUrl="baseUrl"
              @changeSlides="changeSlides" />
              <marketing-slide
              v-if="item.type === 20"
              :item="item"
              :baseUrl="baseUrl"
              @changeSlides="changeSlides"></marketing-slide>

            </div>
          </template>
        </div>
        <a href="#" :class="{ 'visible' : current && (current.progressBar || current.type === 8) }" @click="changeSlides"
           class="continue-button text-primary continue-button-intro-10">weiter</a>
      </div>
      <div class="iframe-popup" @click.self="iframePopUp = false" v-if="iframePopUp">
        <div class="iframe-content-wrapper">
          <iframe :src="iframePopUp.external_link" frameborder="0"></iframe>
          <button @click.stop="iframePopUp = false" class="close btn btn-link btn-sm">
            <img src="@/assets/images/cancel.svg" alt="">
          </button>
        </div>
      </div>
      <videoWrapper
        v-if="current && current.video_box && current.video_box.length >= 1"
        :isOpened="panels.videoWrapper"
        :videos="current.video_box || null"
        v-on:togglePanel="togglePanel"></videoWrapper>
      <sideMenu
        :isOpened="panels.sideMenu"
        v-on:togglePanel="togglePanel"
        v-on:handleIntroVideo="handleIntroVideo"
        :slides="slides"
        :currentSlide="currentSlide"
        :hasIntro="false"
        :intro="intro"
        v-on:changeSlides="changeSlides"></sideMenu>
      <infoWrapper
        v-if="showInfoBox"
        :isOpened="panels.infoWrapper"
        v-on:togglePanel="togglePanel"
        :tabs="infoBoxData"></infoWrapper>
      <bottomVideos
        :isOpened="panels.bottomVideos"
        v-on:togglePanel="togglePanel"></bottomVideos>
      <password-modal
        :slide="slideWithPassword"
        v-on:allowViewSlide="slideWithPassword = null; changeSlides(currentSlide)"
        v-if="slideWithPassword"
      ></password-modal>
    </div>
  </template>

<script>
/* eslint-disable */
  import {mapActions, mapState} from 'vuex'
  import dynamicModule from '@/store/utils/dynamicModule'
  import slidesStore from '@/store/modules/slides'
  import infoWrapper from '@/components/infoWrapper'
  import sideMenu from '@/components/sideMenu'
  import bottomVideos from '@/components/bottomVideos'
  import backToHomeButton from '@/components/backToHomeButton'
  import videoWrapper from "@/components/videoWrapper"
  import anschlussSlide from '@/components/intro11/anschluss-slide'
  import tipp from '@/components/intro11/tipp'
  import TariffCompare from '@/components/intro18-gk/tariffCompare'
  import TariffCompareCustom from '@/components/intro18-gk/tariffCompareCustom'
  import TariffCompareSimple from '@/components/intro18-gk/tariffCompareSimple'
  import TariffCompareCards from '@/components/intro18-gk/tariffCompareCards.vue'
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import marketingSlide from '@/components/marketingSlide'
  import PasswordModal from '@/components/passwordModal'
  
  export default {
    name: 'app',
    mixins: [
      dynamicModule('slides', slidesStore)
    ],
    computed: {
      ...mapState('slides', ['baseUrl', 'intro', 'slides', 'introInfoPage'])
    },
    watch: {
      current (nv, ov) {
        this.initEvents(false)
        if (nv && nv.has_password) {
          this.current = null
          this.slideWithPassword = nv
          this.showHome = true
        }
        // console.log(nv && nv.progressBar === undefined && nv.type !== 8)
      },
      currentSlide(nv, ov) {
        if (nv !== null) {
          this.closeMenu()
  
          switch (nv) {
            case 0:
              this.handleInfoBox(this.introInfoPage || null)
              break
            default:
              this.handleInfoBox(this.current?.info_page || null)
              break
          }
        }
      }
    },
    data() {
      return {
        currentSlide: 1,
        animate: true,
        showLoader: false,
        digitalCheckout: false,
        backToHomeButtonText: 'Speed Up',
        current: null,
        showIntro: false,
        showHome: true,
        isAnimating: false,
        imageCache: [],
        iframePopUp: null,
        isVideoPlaying: true,
        panels: {
          'sideMenu': false,
          'infoWrapper': false,
          'bottomVideos': false,
          'videoWrapper': false
        },
        xDown: null,
        yDown: null,
        slideVideo: false,
        showInfoBox: false,
        infoBoxData: null,
        isSliderActive: false,
        showSplash: false,
        sliderOptions: {
          effect: 'slide',
          grabCursor: true,
          slidesPerView: 1,
          centerSlides: true,
          centeredSlidesBounds: true,
          infinite: true,
          navigation: {
            nextEl: '.swiper-button-next-u',
            prevEl: '.swiper-button-prev-u'
          },
          loop: true
        },
        dropdownMenu: {
          index: null,
          list: [],
          show: false
        },
        tariffFilters: [
          {
            id: 1,
            title: 'Flex',
            code: 'flex',
            active: false,
            dependencies: false,
            combo: null
          },
          {
            id: 2,
            title: 'Aktion 6 Monate',
            code: 'aktion_6_monate',
            active: false,
            dependencies: [3, 4],
            combo: ['2_3', '2_4', '2_3_4']
          },
          {
            id: 3,
            title: 'Durchschnittspreis',
            code: 'durchschnittspreis',
            active: false,
            not_unique: true,
            dependencies: [2, 4, 5],
            combo: ['2_3', '2_3_4', '3_4_5']
          },
          {
            id: 4,
            title: 'MagentaTV Smart',
            code: 'magenta_tv_smart',
            active: false,
            dependencies: [2, 3, 5],
            combo: ['2_4', '4_5', '2_3_4', '3_4_5']
          },
          {
            id: 5,
            title: 'Nebenkostenprivileg',
            code: 'nebenkostprivileg',
            active: false,
            not_unique: true,
            dependencies: [3, 4],
            combo: ['4_5', '3_4_5']
          }
        ],
        tariffCombinations: [
          '2_3',
          '2_4',
          '2_5',
          '2_3_4',
          '3_4_5',
          '4_5'
        ],
        slideWithPassword: null,
      }
    },
    components: {
      infoWrapper,
      sideMenu,
      bottomVideos,
      backToHomeButton,
      Swiper,
      SwiperSlide,
      videoWrapper,
      anschlussSlide,
      tipp,
      TariffCompare,
      TariffCompareCustom,
      marketingSlide,
      TariffCompareSimple,
      TariffCompareCards,
      PasswordModal
    },
    methods: {
      ...mapActions('slides', ['searchSlides', 'clearSlide', 'addLoading']),
      handleIntroVideo() {
        this.showIntro = true
        this.currentSlide = 1
        this.current = this.slides[this.currentSlide]
        // this.current = null
        let video = this.$refs.$video
        if (video) {
          let playingState = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
          if (playingState) {
            video.pause()
            this.isVideoPlaying = false
          } else {
            video.play()
            this.isVideoPlaying = true
          }
        }
      },
      progressBarScrollEvent(obj) {
        // if(obj){
        //   let dir = Math.sign(obj.deltaY);
        //   obj = obj.target;
        //
        //   if(obj.scrollTop === (obj.scrollHeight - obj.offsetHeight) &&  dir === 1){
        //     this.changeSlides(this.currentSlide + 1)
        //   } else if(obj.scrollTop === 0 && dir === -1) {
        //     this.changeSlides(this.currentSlide -1)
        //   }
        // }
      },
      openIframePopup(item) {
        if (item.is_iframe)
          this.iframePopUp = item
        else
          window.open(item.external_link)
      },
      hideIntro(changeSlide = true) {
        this.currentSlide = 1
        this.changeSlides()
      },
      changeSlides(id) {
        if (this.isAnimating || this.isSliderActive) {
          return false
        }
        if (id !== null && id instanceof MouseEvent) {
          id = null
        }
  
  
        if (this.current && this.current.innerSlider && !this.isSliderActive) {
          this.isSliderActive = true
          return
        }
  
        this.isAnimating = true
        this.showLoader = true
        this.animate = true
  
        /*    if (this.slides[this.currentSlide + 1].splashPosition) {
             setTimeout(() => { this.showSplash = true }, 1000)
           } else { this.showSplash = false } */
  
        setTimeout(() => {
          this.showLoader = false
          this.showIntro = false
          if (id === undefined || id === null) {
            this.currentSlide++
          } else if (typeof id === 'string' && ['r', 'l', 'u', 'd'].indexOf(id) > -1) {
            if (id === 'l' || id === 'u') this.currentSlide++
            else this.currentSlide--
          } else this.currentSlide = id
  
          if (this.currentSlide > (Object.keys(this.slides).length) || this.currentSlide === 0) this.handleIntroVideo()
          this.current = this.currentSlide === 0 ? this.slides[0] : this.slides[this.currentSlide]
  
          // if (this.current && (this.current.progressBar !== undefined || this.current.type === 8)) {
          //   this.initEvents(false)
          // }
  
          setTimeout(() => {
            this.animate = false
            this.isAnimating = false
            if (this.$route.params?.id) {
              this.addLoading(false)
            }
          }, 300)
        }, 300)
      },
      togglePanel(panelName, value) {
        for (let item in this.panels) {
          if (item === panelName) this.panels[panelName] = !value
          else this.panels[item] = false
        }
      },
      getTouches(evt) {
        return evt.touches || evt.originalEvent.touches
      },
      handleTouchStart(evt) {
        const firstTouch = this.getTouches(evt)[0]
        this.xDown = firstTouch.clientX
        this.yDown = firstTouch.clientY
      },
      handleTouchMove(evt) {
        let $this = this
        if (!this.xDown || !this.yDown || $this.isAnimating) {
          return
        }
  
        var xUp = evt.touches[0].clientX
        var yUp = evt.touches[0].clientY
  
        var xDiff = this.xDown - xUp
        var yDiff = this.yDown - yUp
  
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          if (xDiff > 0) {
            $this.changeSlides('l')
          } else {
            $this.changeSlides('r')
          }
        } else {
          if (yDiff > 0) {
            $this.changeSlides('u')
          } else {
            $this.changeSlides('d')
          }
        }
        /* reset values */
        this.xDown = null
        this.yDown = null
      },
      wheelFunction(e) {
        let $this = this
        var dir = Math.sign(e.deltaY)
        if ((this.currentSlide === 0 && dir === -1) || e.toElement.classList.contains('progressList')) {
          e.stopPropagation()
          return false
        }
  
        if (!$this.isAnimating) {
          if (dir === -1) {
            $this.changeSlides('r')
          } else {
            $this.changeSlides('l')
          }
        }
        e.preventDefault()
        e.stopPropagation()
        return false
      },
      initEvents(bool) {
        let $this = this
  
        let ele = document.querySelector('.slides-wrapper')
        if (bool) {
          ele.addEventListener('touchstart', $this.handleTouchStart, false)
          ele.addEventListener('touchmove', $this.handleTouchMove, false)
          ele.addEventListener('wheel', $this.wheelFunction)
        } else {
          ele.removeEventListener('touchstart', $this.handleTouchStart, false)
          ele.removeEventListener('touchmove', $this.handleTouchMove, false)
          ele.removeEventListener('wheel', $this.wheelFunction)
        }
      },
      removeWheelEvent(bool) {
        let $this = this
  
        let ele = document.querySelector('.slides-wrapper')
        if (bool) {
          // ele.addEventListener('touchstart', $this.handleTouchStart, false)
          // ele.addEventListener('touchmove', $this.handleTouchMove, false)
          ele.addEventListener('wheel', $this.wheelFunction)
        } else {
          // ele.removeEventListener('touchstart', $this.handleTouchStart, false)
          // ele.removeEventListener('touchmove', $this.handleTouchMove, false)
          ele.removeEventListener('wheel', $this.wheelFunction)
        }
      },
      openVideo(url) {
        this.slideVideo = (url === undefined) ? '://0' : this.baseUrl + url
      },
      handleInfoBox(data) {
        this.infoBoxData = data?.content_elements || null
        this.showInfoBox = data?.content_elements?.length > 0
      },
      handleMenu(navItem, index) {
        if (this.dropdownMenu.show) {
          this.dropdownMenu.show = false;
          this.changeSlides(index)
        } else {
          this.dropdownMenu = {
            index: null,
            list: [],
            show: false
          }
          this.dropdownMenu.index = index
          for (let key in this.slides) {
            // console.log(parseInt(this.slides[key].parentId) + '-' + parseInt(navItem.id), this.slides[key]);
            if (this.slides[key] && parseInt(this.slides[key].parentId) === parseInt(navItem.id) && parseInt(this.slides[key].parentId) !== parseInt(this.slides[key].id)) {
              let item = this.slides[key]
              item.realIndex = parseInt(key)
              this.dropdownMenu.list.push(item)
            }
          }
          if (this.dropdownMenu.list.length > 0) {
            this.dropdownMenu.show = true
          } else {
            this.changeSlides(index)
          }
        }
      },
      closeMenu() {
        this.dropdownMenu = {
          index: null,
          list: [],
          show: false
        }
      },
      handleSubMenu(index) {
        this.changeSlides(index)
        this.closeMenu()
      }
    },
    mounted() {
      let self = this
      let routeID = this.$route.params?.id
      
      this.searchSlides(18).then(() => {
        self.handleInfoBox(this.introInfoPage)
        this.$nextTick(() => {
          Object.keys(this.slides).forEach((key) => {
            let b = new Image()
            b.src = self.slides[key].img
            self.imageCache.push(b)
          })
          if (routeID) {
            let slug = Object.keys(this.slides).filter((el) => this.slides[el].slug === this.$route.params?.id)
            if (slug.length > 0) { routeID = slug[0] } else if (!isNaN(this.$route.params?.id)) { routeID = this.$route.params?.id }
          }
          // if (routeID && routeID <= Object.keys(this.slides).length) { this.showIntro = false; this.changeSlides(routeID) }
  
          if (routeID && routeID <= Object.keys(this.slides).length) {
            this.showLoader = true
            this.animate = this.isAnimating = false
            setTimeout(() => {
              this.changeSlides(routeID)
            }, 300)
          } else {
            this.current = this.slides[this.currentSlide]
          }
          self.initEvents(false)
        })
      })
      // this.showHome = this.$route.query.hm === undefined && this.$route.params.id === undefined
      this.showHome = true
    },
    beforeDestroy() {
      this.clearSlide()
    },
  }
  </script>
  