<template>
    <div class="a-slide-content cardTypesTariff">
      <!-- <div class="a-slide-content-wrapp" @click.self="$emit('handleChange')"> -->
      <div class="a-slide-content-wrapp" @click.stop="triggerChange">
        <div class="a-slide-content-wrapp-title" v-text="item.title"></div>
        <div class="a-slide-content-wrapp-container">
          <div class="firstSlider halfSlides tariffe-slider">
            <div class="tariffe-badge" v-if="showBadge">
              <span v-text="item.badge_text"></span>
            </div>
            <swiper ref="tariffeSlider" :options="sliderOptions">
              <template v-for="(subItem, i) in item.packages">
                <template v-if="subItem[activeKey] && (!activeDiscount || (activeDiscount && i !== 'xl'))">
                  <swiper-slide :key="'swiper-i-4-' + i">
                    <div class="tariffe-inner-slide">
                      <div class="tariffe-slider--badge" v-if="subItem[activeKey].show_badge == 1">Empfehlung</div>
                      <div class="slide-title" :class="{ 'small': subItem[activeKey].title.length > 5 }" v-text="subItem[activeKey].title || i"></div>
                      <div class="slide-subtitle mb-0 font-weight-bold" v-text="subItem[activeKey].donwload_speed || '-'"></div>
                      <div v-if="subItem[activeKey].upload_speed" class="slide-subtitle small" v-text="subItem[activeKey].upload_speed || '-'"></div>
                      <ul class="slide-list-cont" v-if="subItem[activeKey] && subItem[activeKey].content_list.length">
                        <li v-for="(ssItem, ind) in subItem[activeKey].content_list" :key="'li-key-'+ind" v-text="ssItem.title" :class="{
                        'text-primary font-weight-bold' : ssItem.text_purple == 1,
                        'show--tick' : ssItem.show_tick == 1
                      }"></li>
                      </ul>
                      <div class="slide-info" v-if="subItem[activeKey].info" v-text="subItem[activeKey].info"></div>
                      <div class="slide-price-wrapper" v-if="!activeCardNumber()">
                        <div class="slide-price" :class="{ 'helper': subItem[activeKey].price_helper }">
                          {{ filterPrice(subItem[activeKey].price) + ' €' }}<br/>
                          <small v-if="subItem[activeKey].price_helper" v-text="subItem[activeKey].price_helper"></small>
                        </div>
                      </div>
                      <div class="slide-price-wrapper" v-else>
                        <div class="slide-card-label">Für <span v-text="activeCardNumber() + 1"></span> Karten:</div>
                        <div class="slide-price">Ø <span v-text="filterPriceCard(subItem[activeKey])"></span> €</div>
                        <div class="slide-total">Gesamtpreis <span v-text="subItem[activeKey].total"></span> €</div>
                      </div>
                    </div>
                  </swiper-slide>
                </template>
              </template>
              <div class="swiper-pagination custom-pag-6" id="pagination-type-four" slot="pagination"></div>
              <div class="swiper-button-prev custom-pag-6" id="type-four-prev" slot="button-prev"></div>
              <div class="swiper-button-next custom-pag-6" id="type-four-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="a-slide-content-wrapp-filters">
            <!-- <div class="a-slide-content-wrapp-badge">
              Router Gutschrift <br/> 70 €
            </div> -->
            <ul>
              <template v-for="(itm, i) in filters">
                <li :key="'slide-4-filters-' + i" v-if="item[itm.code] && item[itm.code] != '\r\n' && itm.discount == undefined">
                  <div class="checkpanel">
                    <div class="rowInput" :class="{ active: itm.active }">
                      <label class="toggleCheckbox medium">
                        <input :checked="itm.active" :name="itm.code" @input.stop="handleInput(itm, i)" type="checkbox" class="hiddenInput"/>
                        <span>
                          <Toggle :checked="itm.active" />
                        </span>
                      </label>
                      <p class="active" v-text="itm.title"></p>
                      <div class="tooltip-placeholder">
                        <button class="btn icon" :class="'button-m-' + item.id + '-' + itm.id" @click.stop="handleTooltip('button-m-'+ item.id + '-' + itm.id)" @blur="handleTooltip(null)">
                          <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                        </button>
                      </div>
                      <div v-if="tooltip && tooltip === ('button-m-' + item.id + '-' + itm.id)" class="tooltip-holder right" v-html="item[itm.code]"></div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div class="a-slide-content-wrapp-filters__discount">
              <span class="title">Preisvorteil</span>
              <div class="discountSelect">
                <span @click.stop="discountDropdown = !discountDropdown">
                  {{ activeDiscount ? activeDiscountItem.discount + ' %' : '0%' }}
                  <span class="arrow"></span>
                </span>
                <ul v-if="discountDropdown">
                  <li :key="'inner-df-f' + i" :class="{ active: !activeDiscount }" v-text="'0%'" @click.stop="handleDiscountInput(false)"></li>
                  <template v-for="(itm, i) in discountFilters">
                    <li :key="'inner-df-' + i" :class="{ active: itm.discount == activeDiscount }" v-text="itm.discount + '%'" @click.stop="handleDiscountInput(itm, i)"></li>
                  </template>
                </ul>
              </div>
              <span class="discountTooltip" v-if="activeDiscountItem">
                <div class="tooltip-placeholder">
                  <button class="btn icon" :class="'button-m-' + item.id + '-' + activeDiscountItem.id" @click.stop="handleTooltip('button-m-'+ item.id + '-' + activeDiscountItem.id)" @blur="handleTooltip(null)">
                    <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                  </button>
                </div>
                <div v-if="tooltip && tooltip === ('button-m-' + item.id + '-' + activeDiscountItem.id)" class="tooltip-holder right" v-html="item[activeDiscountItem.code]"></div>
              </span>
            </div>
            <!-- <ul v-if="false">
              <template v-for="(itm, i) in discountFilters">
                <li :key="'slide-4-filters-discount-' + i" v-if="item[itm.code] && item[itm.code] != '\r\n'">
                  <div class="checkpanel">
                    <div class="rowInput" :class="{ active: itm.active }">
                      <label class="toggleCheckbox medium">
                        <input :checked="itm.active" :name="itm.code" @input.stop="handleDiscountInput(itm, i)" type="checkbox" class="hiddenInput"/>
                        <span>
                          <Toggle :checked="itm.active" />
                        </span>
                      </label>
                      <p class="active" v-text="itm.title"></p>
                      <div class="tooltip-placeholder">
                        <button class="btn icon" :class="'button-m-' + item.id + '-' + itm.id" @click.stop="handleTooltip('button-m-'+ item.id + '-' + itm.id)" @blur="handleTooltip(null)">
                          <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                        </button>
                      </div>
                      <div v-if="tooltip && tooltip === ('button-m-' + item.id + '-' + itm.id)" class="tooltip-holder right" v-html="item[itm.code]"></div>
                    </div>
                  </div>
                </li>
              </template>
            </ul> -->
            <div class="a-slide-content-wrapp-filters__calc" :class="{'disabled': false }">
              <!-- <div class="calcTitle">
                <h5>Wie viele Karten möchten Sie?
                  <div class="tooltip-placeholder">
                    <button class="btn icon config-m-1" :disabled="!['eins', 'main'].includes(activeItem)" :class="'config-m-1'" @click.stop="handleTooltip('config-m-1')" @blur="handleTooltip(null)">
                      <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                    </button>
                  </div>
                  <div v-if="tooltip && tooltip === 'config-m-1'" class="tooltip-holder right" v-html="item.config_tooltip"></div>
                </h5>
              </div> -->
              <template v-for="(cardType, i) in card_types">
                <div class="calcRow" :class="{'active': cardType.active}" :key="'cardType-' + i">
                  <span class="calcRow__title">
                    {{ cardType.title  + ` ${cardType.selected > 0 ? '(' + cardType.selected + (cardType.selected > 1 ? ' Karten)' : ' Karte)') : ''} ` }}
                    <span class="arrow" @click="setActiveCardType(cardType)"></span>
                    <div class="tooltip-placeholder">
                      <button class="btn icon config-m-1" :class="'config-m-1'" @click.stop="handleTooltip('card-type-' + i)" @blur="handleTooltip(null)">
                        <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                      </button>
                    </div>
                    <div v-if="tooltip && tooltip === 'card-type-' + i" class="tooltip-holder right" v-html="cardType.tooltip"></div>
                  </span>
                  <template v-if="cardType.active">
                    <div v-for="(option, j) in cardType.options" class="calcRow__tools" :key="'ct-option-' + j">
                      <span class="title" v-text="`${option.title} - ${priceFormater(option.price)}€`"></span>
                      <button class="btn decrease" :disabled="option.value < 1" @click="handleCards(cardType, option, '-')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="3" viewBox="0 0 21 3">
                          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5) rotate(-90)">
                            <path id="Path_963" data-name="Path 963" d="M0,0V18" fill="none" stroke="#f9eaf2" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                          </g>
                        </svg>
                      </button>
                      <span class="currentValue" v-text="option.value"></span>
                      <button class="btn increase" :disabled="(!parseInt(cardType.primary) && primaryCardType.selected < 1) || (cardType.selected >= cardType.limit) " @click="handleCards(cardType, option, '+')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5)">
                            <path id="Path_967" data-name="Path 967" d="M18,7.5v21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Path_968" data-name="Path 968" d="M7.5,18h21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </template>
                </div>
              </template>
              <!-- <button class="btn resetCalculator" @click="resetCalculator">Reset</button> -->
              <button class="btn resetCalculator" @click="resetCalculator">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="27" viewBox="0 0 31.5 27">
                  <path id="Icon_material-settings-backup-restore" data-name="Icon material-settings-backup-restore" d="M18,4.5A13.5,13.5,0,0,0,4.5,18H0l6,6,6-6H7.5a10.507,10.507,0,1,1,4.41,8.55L9.78,28.71A13.5,13.5,0,1,0,18,4.5Z" transform="translate(0 -4.5)" fill="#fff"/>
                </svg>
              </button>
            </div>
            <div class="a-slide-content-wrapp-links" v-if="item.slide_links && item.slide_links.length">
              <div class="d-flex flex-column" v-for="(link, i) in item.slide_links" :key="'sl_lnk-' + i">
                <a :href="link.link" v-text="link.title" target="_blank" class="btn"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Toggle from '@/components/icons/toggle'
export default {
  components: {
    Swiper,
    SwiperSlide,
    Toggle
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    filterId: {
      default: null
    }
    // filters: {
    //   type: [Array],
    //   default: () => ([])
    // },
    // combinations: {
    //   type: [Array],
    //   default: () => ([])
    // }
  },
  watch: {
    activeItem: {
      handler (val) {
        this.filteredPackages()
      },
      immediate: true
    }
  },
  computed: {
    swiper () {
      return this.$refs?.tariffeSlider?.$swiper
    },
    activeKey () {
      return this.activeItem.includes('_') ? this.activeItem.split('_').join('') : this.activeItem
    },
    discountFilters () {
      return this.filters.filter(flt => flt.discount !== undefined && this.item[flt.code] && this.item[flt.code] !== '\r\n')
    },
    activeDiscountItem () {
      return this.discountFilters.find(flt => flt.active)
    },
    primaryCardType () {
      return this.card_types.find(ct => parseInt(ct.primary))
    }
  },
  data () {
    return {
      showBadge: false,
      activeItem: '0',
      defSlides: [],
      activeCategory: 1,
      filters: [
        {
          id: 1,
          title: 'MagentaEINS',
          code: 'magentaeins',
          active: false,
          dependencies: false,
          combo: ['1_2', '1_3', '1_4', '1_5', '1_6', '1_7']
        },
        {
          id: 2,
          title: 'Vario',
          code: 'vario',
          active: false,
          dependencies: false,
          combo: ['1_2']
        },
        {
          id: 7,
          title: 'Preisvorteil 5%',
          code: 'preisvorteil_5',
          active: false,
          dependencies: [1],
          combo: ['1_7'],
          // not_unique: true,
          discount: 5
        },
        {
          id: 3,
          title: 'Preisvorteil 10%',
          code: 'preisvorteil_10',
          active: false,
          dependencies: [1],
          combo: ['1_3'],
          // not_unique: true,
          discount: 10
        },
        {
          id: 4,
          title: 'Preisvorteil 15%',
          code: 'preisvorteil_15',
          active: false,
          dependencies: [1],
          combo: ['1_4'],
          // not_unique: true,
          discount: 15
        },
        {
          id: 5,
          title: 'Preisvorteil 20%',
          code: 'preisvorteil_20',
          active: false,
          dependencies: [1],
          combo: ['1_5'],
          // not_unique: true,
          discount: 20
        },
        {
          id: 6,
          title: 'Preisvorteil 25%',
          code: 'preisvorteil_25',
          active: false,
          dependencies: [1],
          // not_unique: true,
          combo: ['1_6'],
          discount: 25
        }
      ],
      card_types: [],
      activeFilter: null,
      activeFilters: [],
      combinations: [
        '1_2',
        '1_3',
        '1_4',
        '1_5',
        '1_6',
        '1_7'
      ],
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next#type-four-next',
          prevEl: '.swiper-button-prev#type-four-prev'
        },
        pagination: {
          el: '.swiper-pagination#pagination-type-four',
          clickable: true
        }
      },
      tooltip: null,
      activeDiscount: false,
      discountDropdown: false
    }
  },
  methods: {
    checkForBadge (active) {
      if (this.item) this.showBadge = this.item.show_badge && this.item.show_badge.indexOf(active) > -1
    },
    handleActiveSlides (id) {
      this.activeCategory = id
      this.swiper.slideTo(0)
      this.swiper.update()
    },
    handleDiscountInput (filter, index) {
      if (filter.active || !filter) {
        if (filter) filter.active = false
        this.activeDiscount = false
        this.discountDropdown = false
        return
      }
      if (this.activeItem === '0' || (!this.activeItem.includes('_') && filter.dependencies.includes(parseInt(this.activeItem)))) {
        this.activeDiscount = filter.discount
        this.discountDropdown = false
      } else return

      this.filters.forEach(flt => {
        if (flt.discount !== undefined) {
          flt.active = filter.id === flt.id
        }
      })
      this.discountDropdown = false
    },
    handleInput (filter, index) {
      // this.filters[index].active = !filter.active
      filter.active = !filter.active
      if (filter.active) {
      //   // Checkbox is checked
        this.activeFilters.push(filter.id)
        this.activeFilters.sort()
        // case combo NULL
        if (!filter.combo || (this.activeItem.includes('0') && !filter.not_unique)) {
          this.activeItem = filter.id.toString()
          this.activeFilters = [filter.id]
          this.resetFilters()
          return
        }
        this.checkItem(filter)
      } else {
        // Checkbox is unchecked
        const index = this.activeFilters.indexOf(filter.id)
        if (index !== -1) {
          this.$delete(this.activeFilters, index)
          if (!this.activeFilters.length) {
            this.activeItem = '0'
          } else {
            this.checkItem(this.filters.find(flt => flt.id === this.activeFilters[0]))
          }
        }
      }
    },
    checkItem (filter) {
      const comboExists = this.combinations.find(combo => combo === this.activeFilters.join('_'))
      if (comboExists) {
        const innerComboExists = filter.combo.includes(comboExists)
        if (innerComboExists) {
          this.setActiveItemAndFilters(comboExists)
        } else {
          this.setActiveItemAndFilters(filter.combo[0])
        }
      } else {
        if (!filter.not_unique) {
          this.setActiveItemAndFilters(filter.id.toString())
        } else {
          this.setActiveItemAndFilters(filter.combo[0])
        }
      }
      this.resetFilters()
    },
    setActiveItemAndFilters (combo) {
      this.activeItem = combo
      this.activeFilters = combo.split('_').map(combo => parseInt(combo))
    },
    handleTooltip (type = null) {
      this.tooltip = type
      if (this.tooltip) {
        let itemClass = document.querySelector('.' + this.tooltip)
        itemClass.focus()
      }
    },
    filteredPackages () {
      let startIndex = null
      let items = -1
      for (let itm in this.item.packages) {
        let item = this.item.packages[itm][this.activeKey]
        if (item) {
          items++
          if (startIndex === null && item.show_first === '1') {
            startIndex = items
          }
        }
      }
      this.$nextTick(() => {
        this.swiper && this.swiper.slideTo(startIndex || 0)
        this.swiper && setTimeout(() => {
          this.swiper.update()
        }, 800)
      })
    },
    resetFilters () {
      this.activeDiscount = false
      for (let i = 0; i < this.filters.length; i++) {
        this.$set(this.filters[i], 'active', this.activeFilters.includes(this.filters[i].id))
      }
    },
    triggerChange (e) {
      if (e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'SPAN' && (!e.target.id || !['type-four-next', 'type-four-prev'].includes(e.target.id))) {
        return false
      }
    },
    filterPrice (val) {
      // console.log(val)
      if (isNaN(parseFloat(val))) return val
      const newVal = parseFloat(val.replace(',', '.'))
      let result = newVal.toFixed(2).replace('.', ',')
      if (this.activeDiscount !== false) {
        result = newVal - (newVal * (this.activeDiscount / 100))
        result = result?.toFixed(2).toString().replace('.', ',')
      }
      return result
    },
    filterPriceCard (item) {
      if (isNaN(parseFloat(item.price))) return item.price
      const newVal = parseFloat(item.price.replace(',', '.'))
      // let result = newVal.toFixed(2).replace('.', ',')
      let result = newVal
      if (this.activeDiscount !== false) {
        result = newVal - (newVal * (this.activeDiscount / 100))
        // result = result?.toFixed(2).toString().replace('.', ',')
      }

      let cards = 0
      let total = 0
      this.card_types.forEach(cardType => {
        cards += cardType.selected
        cardType.options.forEach(option => {
          if (option.value > 0) {
            total += option.value * option.price
          }
        })
      })
      item.total = (result + total).toFixed(2).replace('.', ',')
      return ((result + total) / (cards + 1)).toFixed(2).replace('.', ',')
      // // item.total += total + item.price
      // return ((item.price + total) / cards).toFixed(2)
    },
    handleCards (cardType = null, option = null, operator = null) {
      if (operator === '-') {
        option.value--
        cardType.selected--
      } else {
        option.value++
        cardType.selected++
      }
    },
    resetCalculator () {
      this.setTotals()
      this.card_types.forEach(item => {
        item.selected = 0
        item.options.forEach(option => {
          option.value = 0
        })
      })
    },
    setTotals () {
      for (let item in this.item.packages) {
        for (let innerItem in this.item.packages[item]) {
          this.item.packages[item][innerItem].total = 0
          // this.$set(this.item.packages[item][innerItem], 'total', 0)
        }
      }
    },
    activeCardNumber () {
      let total = 0
      this.card_types.forEach(item => {
        total += item.selected
      })
      return total
    },
    prepareCards () {
      this.card_types.forEach((item, i) => {
        item.selected = 0
        item.active = i === 0
        item.options.forEach(option => {
          option.value = 0
        })
      })
    },
    setActiveCardType (cardType) {
      this.card_types.forEach(item => {
        item.active = false
      })
      cardType.active = !cardType.active
    },
    priceFormater (price) {
      let priceFloat = parseFloat(price)
      return !isNaN(priceFloat) ? priceFloat.toFixed(2).replace('.', ',') : price
    }
  },
  mounted () {
    this.filteredPackages()
    this.setTotals()
    this.prepareCards()
  },
  created () {
    this.card_types = [...this.item.card_types]
    if (this.item.default_preisvorteil) {
      this.activeDiscount = parseFloat(this.item.default_preisvorteil)
      this.filters.find(flt => flt.discount === this.activeDiscount).active = true
    }
  }
}
</script>
